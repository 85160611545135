@import url(https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@500&family=Outfit&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{background-color:#f2edeb}button.btn{background-color:#925e78;border-color:#925e78}button.btn:hover,.button:hover{background-color:#18161e;color:#f2edeb}#top-section{display:flex;flex-direction:row;justify-content:space-between;padding:40px 24px 0 24px}#top-section>*{width:104px}@media screen and (min-width: 768px){#top-section>*{width:300px}}#top-section span.sub{cursor:pointer;color:#18161e;font-family:"Outfit",sans-serif;display:flex;justify-content:flex-end}#top-section span.sub:hover{color:#925e78}#top-section h3{font-family:"Outfit",sans-serif}#first-section{display:flex;justify-content:center}#first-section #name-and-headshot{display:flex;flex-direction:column;max-width:1216px}#first-section #name-and-headshot h1{font-size:104px;background:transparent;font-family:"Birthstone Bounce",cursive}#first-section #name-and-headshot #name-wrapper{display:flex;flex-direction:column;max-height:40em;margin-left:16px;padding:62px 0}@media screen and (min-width: 768px){#first-section #name-and-headshot #name-wrapper{margin:0;flex-direction:row}}#first-section #name-and-headshot .name{margin:0px}#first-section #name-and-headshot img{height:40em;width:auto}#first-section #name-and-headshot p{font-family:"Outfit",sans-serif;font-size:24px;margin:0 16px;padding-bottom:32px}@media screen and (min-width: 768px){#first-section{padding:104px 0}}#info-modal .title{font-family:"Outfit",sans-serif}.modal-body{display:flex;flex-direction:column}#work-examples-title{font-family:"Outfit",sans-serif;font-size:32px;margin-left:16px}.button{font:bold 32px "Outfit",sans-serif;text-decoration:none;background-color:#925e78;color:#f2edeb;padding:4px 16px;border-radius:50px}.contact-me{font:bold 32px "Outfit",sans-serif;text-decoration:none;color:#925e78}.contact-me:hover{color:#18161e}#me{animation:color-change 5s infinite}@keyframes color-change{0%{color:#ff6680}25%{color:#ffe666}50%{color:#6f9}75%{color:#6cf}100%{color:#96f}}
